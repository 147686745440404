<template>
  <header class="container py-10">
    <div class="flex justify-between items-center">
      <NuxtLink
        class="block"
        to="/"
      >
        <img
          src="~/assets/images/podjourney-logo-dark.svg"
          alt="PodPro"
        >
      </NuxtLink>

      <div class="hidden lg:flex space-x-4">
        <VBtn
          class="bg-white text-black border-black"
          @click="onLoginBtnClick"
          @keyup.enter="onLoginBtnClick"
        >
          Login
        </VBtn>

        <VBtn
          class="bg-black text-white border-black"
          @click="onSignupBtnClick"
          @keyup.enter="onSignupBtnClick"
        >
          Signup
        </VBtn>
      </div>

      <button
        class="lg:hidden text-4xl"
        aria-label="Menu"
      >
        <Icon name="mdi:menu" />
      </button>
    </div>
  </header>
</template>

<script setup lang="ts">
const { signIn } = useAuth();

function onLoginBtnClick() {
  signIn('auth0', { callbackUrl: '/dashboard' });
}

function onSignupBtnClick() {
  signIn('auth0', { callbackUrl: '/dashboard' });
}
</script>
