<template>
  <footer class="container pt-28 pb-12">
    <div class="flex flex-col lg:flex-row lg:justify-between lg:items-center space-y-12">
      <NuxtLink
        class="block"
        to="/"
      >
        <img
          src="~/assets/images/podjourney-logo-dark.svg"
          alt="PodPro"
          class="w-44"
        >
      </NuxtLink>

      <div class="flex flex-col lg:flex-row gap-y-8 gap-x-16 font-bold text-base text-dark">
        <NuxtLink
          v-for="(link, i) of topNav"
          :key="i"
          :to="link.to"
        >
          {{ link.label }}
        </NuxtLink>
      </div>

      <div class="flex gap-x-4 text-4xl text-dark">
        <NuxtLink
          v-for="(link, i) of socialNav"
          :key="i"
          :to="link.to"
          :aria-label="link.label"
        >
          <Icon :name="link.icon" />
        </NuxtLink>
      </div>
    </div>

    <hr class="hidden lg:block mt-10">

    <div class="mt-14 flex justify-between items-center font-normal text-sm">
      <div class="hidden lg:block">
        {{ year }} PodJourney. All right reserved.
      </div>

      <div class="flex gap-x-4">
        <NuxtLink
          v-for="(link, i) of bottomNav"
          :key="i"
          :to="link.to"
          class="underline"
        >
          {{ link.label }}
        </NuxtLink>
      </div>

      <div class="hidden lg:block">
        Website by Knockmedia
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const topNav = [
  { label: 'Home', to: '/' },
  { label: 'About', to: '/' },
  { label: 'Pricing', to: '/' },
  { label: 'Contact', to: '/' }
];

const socialNav = [
  { label: 'Facebook', to: '/', icon: 'fa6-brands:facebook-f' },
  { label: 'Instagram', to: '/', icon: 'fa6-brands:instagram' },
  { label: 'Twitter', to: '/', icon: 'fa6-brands:twitter' },
  { label: 'LinkedIn', to: '/', icon: 'fa6-brands:linkedin' }
];

const bottomNav = [
  { label: 'Privacy Policy', to: '/' },
  { label: 'Terms of Service', to: '/' },
  { label: 'Cookies Settings', to: '/' }
];

const year = new Date().getFullYear();
</script>
